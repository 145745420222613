/* LCPage.css */
.answers {
    margin: 0;
    padding: 0;
}

.answer {
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.answer:hover {
    background-color: #999999;
    color: #111111;
}

.selected.correct {
  background-color: #4caf50; /* Green for correct */
  color: #111111;
}


.selected.incorrect1, .selected.incorrect2 {
  background-color: #f44336; /* Red for incorrect */
  color: #111111;
}