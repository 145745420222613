/*
https://www.schemecolor.com/shades-of-gray.php
*/
.App {
    text-align: center;
    background-color: #111111;
    min-height: 100vh;
    color: #999999;
}

.responsive-img {
    max-width: 100%;
    height: auto;
    display: block;
}

@media (max-width: 768px) {
    .row {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .col {
        width: 100%;
        text-align: center;
    }

    .responsive-img {
        margin-top: 20px;
    }
}

